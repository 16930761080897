const registerEditorComponents = (vueInstance) => {
  vueInstance.component('CustomEditorBox', () => import('@/editor/components/CustomEditorBox'));
  vueInstance.component('Container', () => import('@/editor/components/Container'));
  vueInstance.component('FeatureForFeedback', () =>
    import('@/editor/components/modals/FeatureForFeedback.vue'),
  );
  vueInstance.component('CouponSettings', () =>
    import('@/editor/components/modals/CouponSettings.vue'),
  );
  vueInstance.component('MoneyFormat', () => import('@/editor/components/modals/MoneyFormat.vue'));
  vueInstance.component('SmartTagModal', () =>
    import('@/editor/components/modals/SmartTag/SmartTag.vue'),
  );
  vueInstance.component('AddThemeColor', () =>
    import('@/editor/components/modals/themeColor/Add.vue'),
  );
  vueInstance.component('LuckyWheelSettings', () =>
    import('@/editor/components/modals/LuckyWheelSettings.vue'),
  );
  vueInstance.component('ScratchCardSettings', () =>
    import('@/editor/components/modals/ScratchCardSettings.vue'),
  );
  vueInstance.component('ScratchCardTest', () =>
    import('@/editor/components/modals/ScratchCardTest.vue'),
  );
  vueInstance.component('FormChooser', () =>
    import('@/editor/components/modals/formManager/FormChooser.vue'),
  );
  vueInstance.component('FormChooserNew', () =>
    import('@/editor/components/modals/formManager/FormChooserNew.vue'),
  );
  vueInstance.component('FormChooserEdit', () =>
    import('@/editor/components/modals/formManager/FormChooserEdit.vue'),
  );
  vueInstance.component('OmColorInput', () =>
    import('@/editor/components/color/OmColorInputWrapper.vue'),
  );
  vueInstance.component('OmRangeInput', () =>
    import('@/editor/components/sidebar/inputs/RangeInput'),
  );
  vueInstance.component('OmSimpleInput', () =>
    import('@/editor/components/sidebar/inputs/SimpleInput'),
  );
  vueInstance.component('OmDropdownInput', () =>
    import('@/editor/components/sidebar/inputs/DropdownInput'),
  );
  vueInstance.component('OmBackgroundBox', () =>
    import('@/editor/components/sidebar/inputs/OmBackground.vue'),
  );
  vueInstance.component('OmBorderBox', () =>
    import('@/editor/components/sidebar/inputs/OmBorder.vue'),
  );
  vueInstance.component('OmTextShadowBox', () =>
    import('@/editor/components/sidebar/inputs/OmTextShadow.vue'),
  );
  vueInstance.component('OmStyle', () => import('@/editor/components/sidebar/inputs/OmStyle.vue'));
  vueInstance.component('OmFont', () => import('@/editor/components/sidebar/inputs/OmFont.vue'));
  vueInstance.component('OmSize', () => import('@/editor/components/sidebar/inputs/OmSize.vue'));
  vueInstance.component('OmEditorColorPicker', () =>
    import('@/editor/components/color/OmColorPicker'),
  );
  vueInstance.component('OmShadow', () =>
    import('@/editor/components/sidebar/inputs/OmShadow.vue'),
  );
  vueInstance.component('GlobalDisplayBox', () =>
    import('@/editor/components/sidebar/inputs/GlobalDisplay.vue'),
  );
  vueInstance.component('GlobalDisplayBoxBeta', () =>
    import('@/editor/components/sidebar/inputs/GlobalDisplayBeta.vue'),
  );
  vueInstance.component('OmSwitches', () =>
    import('@/editor/components/sidebar/inputs/Switches.vue'),
  );
  vueInstance.component('OmFontAwesomeButton', () =>
    import('@/editor/components/sidebar/inputs/OmFontAwesomeButton.vue'),
  );
  vueInstance.component('OmPopupClose', () =>
    import('@/editor/components/sidebar/inputs/OmCloseButton.vue'),
  );
  vueInstance.component('OmPadding', () =>
    import('@/editor/components/sidebar/inputs/Padding.vue'),
  );
  vueInstance.component('OmMargin', () => import('@/editor/components/sidebar/inputs/Margin.vue'));
  vueInstance.component('OmSimpleImage', () =>
    import('@/editor/components/sidebar/inputs/SimpleImage.vue'),
  );
  vueInstance.component('OmImagePicker', () =>
    import('@/editor/components/sidebar/inputs/ImagePicker.vue'),
  );
  vueInstance.component('QuillEditor', () => import('@/editor/components/sidebar/inputs/Quill'));
  vueInstance.component('SidebarTitle', () => import('@/editor/components/sidebar/SidebarTitle'));
  vueInstance.component('SidebarOption', () =>
    import('@/editor/components/sidebar/SidebarOption.vue'),
  );
  vueInstance.component('Sidebar', () => import('@/editor/components/sidebar/Sidebar'));
  vueInstance.component('SidebarColorPalette', () =>
    import('@/editor/components/sidebar/SidebarColorPalette'),
  );
  vueInstance.component('ThemeFontManager', () =>
    import('@/editor/components/sidebar/ThemeFontManager'),
  );
  vueInstance.component('RoundingManager', () =>
    import('@/editor/components/sidebar/RoundingManager'),
  );
  vueInstance.component('CopyElementId', () => import('@/editor/components/CopyElementId'));
  vueInstance.component('SetElementDefaults', () =>
    import('@/editor/components/SetElementDefaults'),
  );
  vueInstance.component('InputElementChooser', () =>
    import('@/editor/components/InputElementChooser'),
  );
  vueInstance.component('OmProgressiveImage', () => import('@/components/OmProgressiveImage.vue'));
  vueInstance.component('FontDropdown', () =>
    import('@/editor/components/sidebar/inputs/FontDropdown.vue'),
  );
  vueInstance.component('Expandable', () => import('@/editor/components/Expandable.vue'));
  vueInstance.component('SidebarAccordion', () =>
    import('@/editor/components/sidebar/SidebarAccordion.vue'),
  );
  vueInstance.component('OmChooser', () =>
    import('@/editor/components/sidebar/inputs/OmChooser.vue'),
  );
  vueInstance.component('OmMobileSettings', () =>
    import('@/editor/components/sidebar/inputs/MobileSettings.vue'),
  );
  vueInstance.component('OmDeviceChooser', () =>
    import('@/editor/components/sidebar/inputs/OmDeviceChooser.vue'),
  );
  vueInstance.component('CustomHtmlSettings', () =>
    import('@/editor/components/modals/CustomHTMLSettings.vue'),
  );
  vueInstance.component('MissingCustomFields', () =>
    import('@/editor/components/modals/MissingCustomFields.vue'),
  );
  vueInstance.component('PickAPresentSettings', () =>
    import('@/editor/components/modals/PickAPresentSettings.vue'),
  );
  vueInstance.component('PickAPresentTest', () =>
    import('@/editor/components/modals/PickAPresentTest.vue'),
  );
  vueInstance.component('OmAdvancedSettings', () =>
    import('@/editor/components/sidebar/inputs/AdvancedSettings.vue'),
  );
  vueInstance.component('OmInputGroup', () =>
    import('@/editor/components/sidebar/inputs/InputGroup.vue'),
  );
  vueInstance.component('OmResponsiveSettings', () =>
    import('@/editor/components/sidebar/inputs/ResponsiveSettings.vue'),
  );
  vueInstance.component('DesktopSvg', () => import('@/editor/components/svg/DesktopSvg.vue'));
  vueInstance.component('MobileSvg', () => import('@/editor/components/svg/MobileSvg.vue'));
  vueInstance.component('ElementDefaultValues', () =>
    import('@/editor/components/ElementDefaultValues'),
  );
  vueInstance.component('SidebarTabWrapper', () =>
    import('@/editor/components/sidebar/tab/Wrapper.vue'),
  );
  vueInstance.component('SidebarTabItem', () => import('@/editor/components/sidebar/tab/Item.vue'));
  vueInstance.component('SidebarTabContent', () =>
    import('@/editor/components/sidebar/tab/Content.vue'),
  );
  vueInstance.component('SidebarTabPredefinedAdvanced', () =>
    import('@/editor/components/sidebar/tab/predefined/CommonAdvancedTabContent.vue'),
  );
  vueInstance.component('SidebarTabPredefinedStyle', () =>
    import('@/editor/components/sidebar/tab/predefined/CommonStyleTabContent.vue'),
  );
  vueInstance.component('StepInfoBox', () =>
    import('@/editor/components/sidebar/components/infoBox/StepInfoBox.vue'),
  );
};

export { registerEditorComponents };
